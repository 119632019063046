import { HengeAuthTokens } from "@/classes/auth/Auth";
import { GA4_CUSTOM_TRIGGER } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import { InitPreprocessUploadRequest } from "@/types/api/asset/request/asset-request";
import {
  CreateGaiaRequest,
  DropGaiaMemberRequest,
  InviteGaiaMemberRequest,
  UpdateGaiaMemberRoleRequest,
  UpdateGaiaRequest,
  UpdateGaiaThumbnailRequest,
} from "@/types/api/gaia/request/gaia-request";
import {
  GaiaCapsuleResponse,
  GaiaResponse,
} from "@/types/api/gaia/response/gaia-response";
import { UpdateLayerMaterialRequest } from "@/types/api/henge-layer/request/henge-layer-request";
import { CreateHengePinRequest } from "@/types/api/henge-pin/request/henge-pin-request";
import { HengePinResponse } from "@/types/api/henge-pin/response/henge-pin-response";
import {
  CreateHengePinnedRosettaCellRequest,
  UpdateHengePinnedRosettaCellLocationRequest,
} from "@/types/api/henge-pinned-rosetta-cell/request/henge-pinned-rosetta-cell-request";
import {
  CreateHengeRequest,
  UpdateHengeRequest,
  UpdateHengeShareScopeRequest,
} from "@/types/api/henge/request/henge-request";
import {
  HengeListResponse,
  HengeResponse,
  LimitMaxGaiaFileSizeResponse,
  LimitMaxHengeFileSizeResponse,
} from "@/types/api/henge/response/henge-response";
import { SaveToLibraryRequest } from "@/types/api/library/request/library-request";
import { LibraryListResponse } from "@/types/api/library/response/library-response";
import {
  CreateMemberDetailRequest,
  PasswordChangeRequest,
} from "@/types/api/member/request/member-request";
import {
  MemberResponse,
  SearchMemberResponse,
} from "@/types/api/member/response/member-response";
import { GetNotificationListResponse } from "@/types/api/notification/response/notification-response";
import { GenerateChannelTalkHashResponse } from "@/types/api/plugins/channelTalk/response/channel-talk-response";
import { DataResponse, HResponse } from "@/types/api/response";
import {
  CreateRosettaCellRequest,
  UpdateRosettaCellDataRequest,
  UpdateRosettaCellIdxRequest,
  UpdateRosettaCellLocationRequest,
  UpdateRosettaCellMapRequest,
  UploadRosettaCellImageByUrlRequest,
} from "@/types/api/rosetta-cell/request/rosetta-cell-request";
import {
  CreateRosettaCellResponse,
  RosettaCellResponse,
  UploadFileResponse,
} from "@/types/api/rosetta-cell/response/rosetta-cell-response";
import { UpdateRosettaDataRequest } from "@/types/api/rosetta/request/document-rosetta-request";
import { DocumentRosettaResponse } from "@/types/api/rosetta/response/document-rosetta-response";
import { RosettaRegisteredListResponse } from "@/types/api/rosetta/response/tuning-shop-response";
import { SketchfabRequest } from "@/types/api/sketchfab/request/sketchfab-request";
import {
  InspectSketchfabMigrationResponse,
  ScanSketchfabMigrationResponse,
  SketchfabMigrationSummaryResponse,
  SketchfabUserResponse,
} from "@/types/api/sketchfab/response/sketchfab-response";
import {
  ApproveVaultInvitationRequest,
  ApproveVaultJoinReqRequest,
} from "@/types/api/vault-member/request/vault-member-request";
import { CheckVaultMemberResponse } from "@/types/api/vault-member/response/vault-member-response";
import {
  CommentVaultObjectRequest,
  CopyVaultObjectByInventoryRequest,
  CreateVaultObjectRequest,
  MyVaultFeedRequest,
  UpdateVaultObjectRequest,
  ViewingVaultFeedRequest,
} from "@/types/api/vault-object/request/vault-object-request";
import {
  VaultObjectListResponse,
  VaultObjectResponse,
} from "@/types/api/vault-object/response/vault-object-response";
import {
  GetVaultResponse,
  MyVaultsResponse,
} from "@/types/api/vault/response/vault-response";
import { VaultAccess, VaultUploadPolicy } from "@/types/enums";
import { FetchWithHengeAuthCallback } from "@/utils/api/fetch-types";
import fetchWithHengeAuth from "@/utils/auth/fetchWithHengeAuth";

//////////////////
//    member    //
//////////////////

export const fetchGetMemberAPI: FetchWithHengeAuthCallback<MemberResponse> = (
  args,
  authTokens,
) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/member",
      ...args,
    },
    authTokens,
    true,
  );
};

export const fetchGenerateChannelTalkHashAPI: FetchWithHengeAuthCallback<
  GenerateChannelTalkHashResponse,
  { username: string }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/channel-talk",
      ...args,
    },
    authTokens,
  );
};

export const fetchSearchMemberAPI: FetchWithHengeAuthCallback<
  SearchMemberResponse,
  { q: string }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/member/search",
      ...args,
    },
    authTokens,
  );
};

/////////////////////////
//    member-detail    //
/////////////////////////

export const fetchCreateMemberDetailAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: CreateMemberDetailRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/member/detail",
      ...args,
    },
    authTokens,
  );
};

/////////////////////////
//    member-manage    //
/////////////////////////

export const fetchPasswordChangeAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: PasswordChangeRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/member/manage/password/change",
      ...args,
    },
    authTokens,
  );
};

////////////////
//    gaia    //
////////////////

export const fetchMemberHomeGaiaAPI: FetchWithHengeAuthCallback<
  GaiaResponse,
  { queryParams: { username: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia/home",
      ...args,
    },
    authTokens,
  );
};

export const fetchCheckGaiaShareScopeAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { uid: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia/scope",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetGaiaCapsuleListAPI: FetchWithHengeAuthCallback<
  GaiaCapsuleResponse,
  { queryParams: { username: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia/capsule",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetGaiaAPI: FetchWithHengeAuthCallback<
  GaiaResponse,
  { queryParams: { gaiaId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia",
      ...args,
    },
    authTokens,
  );
};

export const fetchCreateGaiaAPI: FetchWithHengeAuthCallback<
  GaiaResponse,
  { json: CreateGaiaRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .POST<GaiaResponse, { json: CreateGaiaRequest }>(
      {
        path: "/api/v1/gaia",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      try {
        window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Gaia_Create());
      } catch (e) {
        void e;
      }

      return res;
    });
};

export const fetchUpdateGaiaAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { gaiaId: number };
    json: UpdateGaiaRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/gaia",
      ...args,
    },
    authTokens,
  );
};

export const fetchUpdateGaiaThumbnailAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { gaiaId: number };
    json: UpdateGaiaThumbnailRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/gaia/thumbnail",
      ...args,
    },
    authTokens,
  );
};

export const fetchDeleteGaiaAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { gaiaId: number };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .DELETE<
      HResponse,
      {
        queryParams: { gaiaId: number };
      }
    >(
      {
        path: "/api/v1/gaia",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      try {
        window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Gaia_Delete());
      } catch (e) {
        void e;
      }

      return res;
    });
};

///////////////////////
//    gaia-member    //
///////////////////////

export const fetchInviteGaiaMemberAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: InviteGaiaMemberRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/gaia-member/invite",
      ...args,
    },
    authTokens,
  );
};

export const fetchUpdateGaiaMemberRoleAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: UpdateGaiaMemberRoleRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/gaia-member/update",
      ...args,
    },
    authTokens,
  );
};

export const fetchDropGaiaMemberAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    json: DropGaiaMemberRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/gaia-member/drop",
      ...args,
    },
    authTokens,
  );
};

export const fetchSearchMemberToInviteAPI: FetchWithHengeAuthCallback<
  SearchMemberResponse,
  {
    queryParams: {
      gaiaId: number;
      q: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/gaia-member/search",
      ...args,
    },
    authTokens,
  );
};

/////////////////
//    henge    //
/////////////////

export const fetchGetHengeListAPI: FetchWithHengeAuthCallback<
  HengeListResponse,
  {
    queryParams: {
      gaiaId: number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/henge/list",
      ...args,
    },
    authTokens,
  );
};

/**
 * @return Promise<HengeResponse | LimitMaxGaiaFileSizeResponse | LimitMaxHengeFileSizeResponse>
 */
export function fetchCreateHengeAPI(
  createHengeRequest: CreateHengeRequest,
  files: { mainFile: File; additionalFiles: File[] },
  authTokens: HengeAuthTokens | undefined | null,
): Promise<
  HengeResponse | LimitMaxGaiaFileSizeResponse | LimitMaxHengeFileSizeResponse
> {
  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";

  const hengeDataInputElement = document.createElement("input");
  hengeDataInputElement.type = "text";
  hengeDataInputElement.name = "createHengeRequest";
  hengeDataInputElement.value = JSON.stringify(createHengeRequest);

  const mainFileDataTransfer = new DataTransfer();
  mainFileDataTransfer.items.add(files.mainFile);

  form.append(hengeDataInputElement);

  const mainFileInputElement = document.createElement("input");
  mainFileInputElement.type = "file";
  mainFileInputElement.name = "mainFile";
  mainFileInputElement.files = mainFileDataTransfer.files;

  form.append(mainFileInputElement);

  if (files.additionalFiles.length > 0) {
    const additionalFilesDataTransfer = new DataTransfer();
    files.additionalFiles.forEach((file) => {
      additionalFilesDataTransfer.items.add(file);
    });

    const additionalFilesInputElement = document.createElement("input");
    additionalFilesInputElement.type = "file";
    additionalFilesInputElement.name = "additionalFiles";
    additionalFilesInputElement.files = additionalFilesDataTransfer.files;

    form.append(additionalFilesInputElement);
  }

  const formData = new FormData(form);

  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge",
      ...{
        init: {
          body: formData,
        },
      },
    },
    authTokens,
  );
}

export const fetchUpdateHengeAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengeId: number;
    };
    json: UpdateHengeRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/henge",
      ...args,
    },
    authTokens,
  );
};

// export const fetchUpdateHengeThumbnailAPI: FetchWithHengeAuthCallback<
//   HResponse,
//   {
//     queryParams: {
//       hengeId: number;
//     };
//     json: UpdateHengeThumbnailRequest;
//   }
// > = (args, authTokens) => {
//   return fetchWithHengeAuth.PUT(
//     {
//       path: "/api/v1/henge/thumbnail",
//       ...args,
//     },
//     authTokens,
//   );
// };

export function fetchUpdateHengeOGImageAPI(
  hengeId: number,
  image: File,
  authTokens: HengeAuthTokens | undefined | null,
): Promise<HengeResponse> {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(image);

  const fileInputElement = document.createElement("input");
  fileInputElement.type = "file";
  fileInputElement.name = "image";
  fileInputElement.files = dataTransfer.files;

  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";
  form.append(fileInputElement);

  const formData = new FormData(form);

  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge/og-image",
      ...{
        queryParams: {
          hengeId,
        },
        init: {
          body: formData,
        },
      },
    },
    authTokens,
  );
}

export const fetchUpdateHengeShareScopeAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengeId: number;
    };
    json: UpdateHengeShareScopeRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/henge/share/scope",
      ...args,
    },
    authTokens,
  );
};

export const fetchDeleteHengeAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengeId: number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/henge",
      ...args,
    },
    authTokens,
  );
};

/////////////////
//    Asset    //
/////////////////

export const fetchGetAssetSignedUrl: FetchWithHengeAuthCallback<
  DataResponse<string>,
  {
    queryParams: {
      url: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/asset/signed-url",
      ...args,
    },
    authTokens,
    true,
  );
};

export const fetchGenerateAutoRig: FetchWithHengeAuthCallback<
  DataResponse<string>,
  {
    queryParams: {
      assetId: number;
      height: number;
      x: number;
      y: number;
      z: number;
      markers: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/asset/auto-rig",
      ...args,
    },
    authTokens,
  );
};

export const fetchInitPreprocessUploadAPI: FetchWithHengeAuthCallback<
  DataResponse<string>,
  { json: InitPreprocessUploadRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/asset/preprocess-upload",
      ...args,
    },
    authTokens,
  );
};

export function fetchPreprocessUploadAPI(
  vaultUid: string,
  preprocessUid: string,
  mainFile: File,
  additionalFiles: File[],
  authTokens: HengeAuthTokens,
  abortSignal: AbortSignal,
): Promise<Response> {
  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "PUT";

  const mainFileDataTransfer = new DataTransfer();
  mainFileDataTransfer.items.add(mainFile);

  const mainFileInputElement = document.createElement("input");
  mainFileInputElement.type = "file";
  mainFileInputElement.name = "mainFile";
  mainFileInputElement.files = mainFileDataTransfer.files;

  form.append(mainFileInputElement);

  if (additionalFiles.length > 0) {
    const additionalFilesDataTransfer = new DataTransfer();
    additionalFiles.forEach((file) =>
      additionalFilesDataTransfer.items.add(file),
    );

    const additionalFilesInputElement = document.createElement("input");
    additionalFilesInputElement.type = "file";
    additionalFilesInputElement.name = "additionalFiles";
    additionalFilesInputElement.files = additionalFilesDataTransfer.files;

    form.append(additionalFilesInputElement);
  }

  const formData = new FormData(form);

  return fetch(
    `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}/api/v1/asset/preprocess-upload?vaultUid=${vaultUid}&uid=${preprocessUid}`,
    {
      headers: {
        "x-hng-access-token": `Bearer ${authTokens.accessToken.value}`,
      },
      method: "PUT",
      body: formData,
      signal: abortSignal,
    },
  );
}

///////////////////////
//    henge-layer    //
///////////////////////

export const fetchUpdateLayerMaterialAPI: FetchWithHengeAuthCallback<
  HengePinResponse,
  {
    json: UpdateLayerMaterialRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/henge/layer/material",
      ...args,
    },
    authTokens,
    true,
  );
};

/////////////////////
//    henge-pin    //
/////////////////////

export const fetchCreateHengePinAPI: FetchWithHengeAuthCallback<
  HengePinResponse,
  {
    json: CreateHengePinRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge-pin",
      ...args,
    },
    authTokens,
    true,
  );
};

/////////////////////////////////////
//    henge-pinned-rosetta-cell    //
/////////////////////////////////////
export const fetchCreateHengePinnedRosettaCellAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: {
      hengePinId: number;
      rosettaCellId: number;
    };
    json: CreateHengePinnedRosettaCellRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/henge-pin/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

export const fetchUpdateHengePinnedRosettaCellLocationAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { hengePinId: number; rosettaCellId: number };
    json: UpdateHengePinnedRosettaCellLocationRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/henge-pin/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

export const fetchDeleteHengePinnedRosettaCellAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { hengePinId: number; rosettaCellId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/henge-pin/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

///////////////////
//    rosetta    //
///////////////////

export const fetchGetRosettaAPI: FetchWithHengeAuthCallback<
  DocumentRosettaResponse,
  {
    queryParams: {
      rosettaId: number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/henge-rosetta",
      ...args,
    },
    authTokens,
  );
};

export const fetchAddRosettaAPI: FetchWithHengeAuthCallback<
  DocumentRosettaResponse,
  {
    json: {
      hengeId: number;
      codeName: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      DocumentRosettaResponse,
      {
        json: {
          hengeId: number;
          codeName: string;
        };
      }
    >(
      {
        path: "/api/v1/henge-rosetta/add",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Tuning_Shop_Add_Rosetta());

      return res;
    });
};

export const fetchRemoveRosettaAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    json: {
      hengeId: number;
      codeName: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        json: {
          hengeId: number;
          codeName: string;
        };
      }
    >(
      {
        path: "/api/v1/henge-rosetta/remove",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(
        GA4_CUSTOM_TRIGGER.Fetch.Tuning_Shop_Remove_Rosetta(),
      );

      return res;
    });
};

export const fetchUpdateRosettaDataAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { rosettaId: number }; json: UpdateRosettaDataRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      { queryParams: { rosettaId: number }; json: UpdateRosettaDataRequest }
    >(
      {
        path: "/api/v1/henge-rosetta",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

////////////////////////
//    rosetta-cell    //
////////////////////////

export const fetchGetRosettaCellAPI: FetchWithHengeAuthCallback<
  RosettaCellResponse,
  {
    queryParams: {
      id: number[] | number;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/rosetta-cell",
      ...args,
    },
    authTokens,
  );
};

export function fetchUploadRosettaCellFileAPI(
  queryParams: { rosettaCellId: number },
  file: File,
  authTokens: HengeAuthTokens | undefined | null,
): Promise<UploadFileResponse> {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);

  const fileInputElement = document.createElement("input");
  fileInputElement.type = "file";
  fileInputElement.name = "file";
  fileInputElement.files = dataTransfer.files;

  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";
  form.append(fileInputElement);

  const formData = new FormData(form);

  return fetchWithHengeAuth
    .POST<
      UploadFileResponse,
      { queryParams: { rosettaCellId: number }; init: { body: FormData } }
    >(
      {
        path: "/api/v1/rosetta-cell/file",
        ...{ queryParams, init: { body: formData } },
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
}

export const fetchUploadRosettaCellImageByUrlAPI: FetchWithHengeAuthCallback<
  UploadFileResponse,
  {
    queryParams: { rosettaCellId: number };
    json: UploadRosettaCellImageByUrlRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST<
    UploadFileResponse,
    {
      queryParams: { rosettaCellId: number };
      json: UploadRosettaCellImageByUrlRequest;
    }
  >({ path: "/api/v1/rosetta-cell/image-url", ...args }, authTokens);
};

export const fetchCreateRosettaCellAPI: FetchWithHengeAuthCallback<
  CreateRosettaCellResponse,
  { json: CreateRosettaCellRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .POST<CreateRosettaCellResponse, { json: CreateRosettaCellRequest }>(
      {
        path: "/api/v1/rosetta-cell",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchUpdateRosettaCellDataAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { rosettaCellId: number };
    json: UpdateRosettaCellDataRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaCellId: number };
        json: UpdateRosettaCellDataRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

/**
 * @deprecated
 * */
export const fetchUpdateRosettaCellIdxAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { rosettaCellId: number }; json: UpdateRosettaCellIdxRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaCellId: number };
        json: UpdateRosettaCellIdxRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell/idx",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchUpdateRosettaCellLocationAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { rosettaCellId: number };
    json: UpdateRosettaCellLocationRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaCellId: number };
        json: UpdateRosettaCellLocationRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell/location",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchUpdateRosettaCellMapAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { rosettaId: number };
    json: UpdateRosettaCellMapRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .PUT<
      HResponse,
      {
        queryParams: { rosettaId: number };
        json: UpdateRosettaCellMapRequest;
      }
    >(
      {
        path: "/api/v1/rosetta-cell/map",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

export const fetchDeleteRosettaCellAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { rosettaCellId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth
    .DELETE<HResponse, { queryParams: { rosettaCellId: number } }>(
      {
        path: "/api/v1/rosetta-cell",
        ...args,
      },
      authTokens,
    )
    .then((res) => {
      window.dataLayer.push(GA4_CUSTOM_TRIGGER.Fetch.Rosetta_Update_Data());

      return res;
    });
};

///////////////////////
//    tuning-shop    //
///////////////////////

export const fetchGetTuningShopRosettaAPI: FetchWithHengeAuthCallback<
  RosettaRegisteredListResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/tuning-shop",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetTuningShopReleasedRosettaAPI: FetchWithHengeAuthCallback<
  RosettaRegisteredListResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/tuning-shop/released",
      ...args,
    },
    authTokens,
  );
};

////////////////////
//    feedback    //
////////////////////

export const fetchSubmitOverlayFeedbackAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    json: {
      feedback: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/feedback/overlay",
      ...args,
    },
    authTokens,
  );
};

///////////////////
//    Library    //
///////////////////

export const fetchGetLibraryListAPI: FetchWithHengeAuthCallback<
  LibraryListResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/library",
      ...args,
    },
    authTokens,
  );
};

export const fetchSaveToLibraryAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    json: SaveToLibraryRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/library",
      ...args,
    },
    authTokens,
  );
};

export const fetchUnsaveFromLibraryAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { id: number };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/library",
      ...args,
    },
    authTokens,
  );
};

/////////////////
//    Vault    //
/////////////////

export const fetchGetVaultWithHengeAuthAPI: FetchWithHengeAuthCallback<
  GetVaultResponse,
  { queryParams: { uid: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/vault",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetMyPageVaultAPI: FetchWithHengeAuthCallback<
  GetVaultResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/vault/my-page",
      ...args,
    },
    authTokens,
  );
};

export const fetchValidateVaultNameAPI: FetchWithHengeAuthCallback<
  HResponse,
  {
    queryParams: { name: string };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/vault/validate/name",
      ...args,
    },
    authTokens,
  );
};

export function fetchCreateVaultAPI(
  name: string,
  description: string,
  access: VaultAccess,
  uploadPolicy: VaultUploadPolicy,
  image: File,
  authTokens: HengeAuthTokens | undefined | null,
): Promise<GetVaultResponse> {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(image);

  const fileInputElement = document.createElement("input");
  fileInputElement.type = "file";
  fileInputElement.name = "titleLogo";
  fileInputElement.files = dataTransfer.files;

  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";
  form.append(fileInputElement);

  const formData = new FormData(form);

  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/vault",
      queryParams: {
        name,
        description,
        access,
        uploadPolicy,
      },
      init: {
        body: formData,
      },
    },
    authTokens,
  );
}

export function fetchUpdateVaultAPI(
  id: number,
  name: string | null,
  description: string | null,
  access: VaultAccess | null,
  uploadPolicy: VaultUploadPolicy | null,
  image: File | null,
  authTokens: HengeAuthTokens | undefined | null,
): Promise<GetVaultResponse> {
  let formData: FormData | null = null;
  if (image) {
    const form = document.createElement("form");
    form.enctype = "multipart/form-data";
    form.method = "PUT";

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(image);

    const fileInputElement = document.createElement("input");
    fileInputElement.type = "file";
    fileInputElement.name = "titleLogo";
    fileInputElement.files = dataTransfer.files;

    form.append(fileInputElement);

    formData = new FormData(form);
  }

  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/vault",
      queryParams: {
        id,
        name,
        description,
        access,
        uploadPolicy,
      },
      init: formData
        ? {
            body: formData,
          }
        : undefined,
    },
    authTokens,
  );
}

export const fetchDeleteVaultAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { id: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/vault",
      ...args,
    },
    authTokens,
  );
};

// export const fetchVaultListWithHengeAuthAPI: FetchWithHengeAuthCallback<
//   GetVaultListResponse
// > = (args, authTokens) => {
//   return fetchWithHengeAuth.GET(
//     {
//       path: "/api/v1/vault/list",
//       ...args,
//     },
//     authTokens,
//   );
// };

export const fetchGetMyVaultsAPI: FetchWithHengeAuthCallback<
  MyVaultsResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    {
      path: "/api/v1/vault/list",
      ...args,
    },
    authTokens,
  );
};

///////////////////////
//    VaultObject    //
///////////////////////

export function fetchCreateVaultObjectByUploadAPI(
  request: CreateVaultObjectRequest,
  // files: { mainFile: File; additionalFiles: File[] },
  mainFile: File,
  textureFiles: File[] | undefined,
  thumbnailImage: File,
  backgroundImage: File | undefined,
  authTokens: HengeAuthTokens | undefined | null,
): Promise<VaultObjectResponse> {
  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";

  const requestDataElement = document.createElement("input");
  requestDataElement.type = "text";
  requestDataElement.name = "createVaultObjectRequest";
  requestDataElement.value = JSON.stringify(request);

  form.append(requestDataElement);

  const mainFileDataTransfer = new DataTransfer();
  mainFileDataTransfer.items.add(mainFile);

  const mainFileInputElement = document.createElement("input");
  mainFileInputElement.type = "file";
  mainFileInputElement.name = "mainFile";
  mainFileInputElement.files = mainFileDataTransfer.files;

  form.append(mainFileInputElement);

  if (textureFiles && textureFiles.length > 0) {
    const textureFilesDataTransfer = new DataTransfer();
    textureFiles.forEach((file) => {
      textureFilesDataTransfer.items.add(file);
    });

    const textureFilesInputElement = document.createElement("input");
    textureFilesInputElement.type = "file";
    textureFilesInputElement.name = "textureFiles";
    textureFilesInputElement.files = textureFilesDataTransfer.files;

    form.append(textureFilesInputElement);
  }

  const thumbnailImageDataTransfer = new DataTransfer();
  thumbnailImageDataTransfer.items.add(thumbnailImage);

  const thumbnailImageInputElement = document.createElement("input");
  thumbnailImageInputElement.type = "file";
  thumbnailImageInputElement.name = "thumbnailImage";
  thumbnailImageInputElement.files = thumbnailImageDataTransfer.files;

  form.append(thumbnailImageInputElement);

  if (backgroundImage) {
    const backgroundImageDataTransfer = new DataTransfer();
    backgroundImageDataTransfer.items.add(backgroundImage);

    const backgroundImageInputElement = document.createElement("input");
    backgroundImageInputElement.type = "file";
    backgroundImageInputElement.name = "backgroundImage";
    backgroundImageInputElement.files = backgroundImageDataTransfer.files;

    form.append(backgroundImageInputElement);
  }

  const formData = new FormData(form);

  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/vault-object/create-upload",
      ...{
        init: {
          body: formData,
        },
      },
    },
    authTokens,
  );
}

export function fetchUpdateVaultObjectPI(
  uid: string,
  request: UpdateVaultObjectRequest,
  mainFile: File | undefined,
  textureFiles: File[] | undefined,
  thumbnailImage: File | null,
  backgroundImage: File | undefined,
  authTokens: HengeAuthTokens | undefined | null,
): Promise<VaultObjectResponse> {
  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "PUT";

  const requestDataElement = document.createElement("input");
  requestDataElement.type = "text";
  requestDataElement.name = "updateVaultObjectRequest";
  requestDataElement.value = JSON.stringify(request);

  form.append(requestDataElement);

  if (mainFile) {
    const mainFileDataTransfer = new DataTransfer();
    mainFileDataTransfer.items.add(mainFile);

    const mainFileInputElement = document.createElement("input");
    mainFileInputElement.type = "file";
    mainFileInputElement.name = "mainFile";
    mainFileInputElement.files = mainFileDataTransfer.files;

    form.append(mainFileInputElement);
  }

  if (textureFiles && textureFiles.length > 0) {
    const textureFilesDataTransfer = new DataTransfer();
    textureFiles.forEach((file) => {
      textureFilesDataTransfer.items.add(file);
    });

    const textureFilesInputElement = document.createElement("input");
    textureFilesInputElement.type = "file";
    textureFilesInputElement.name = "textureFiles";
    textureFilesInputElement.files = textureFilesDataTransfer.files;

    form.append(textureFilesInputElement);
  }

  if (thumbnailImage) {
    const thumbnailImageDataTransfer = new DataTransfer();
    thumbnailImageDataTransfer.items.add(thumbnailImage);

    const thumbnailImageInputElement = document.createElement("input");
    thumbnailImageInputElement.type = "file";
    thumbnailImageInputElement.name = "thumbnailImage";
    thumbnailImageInputElement.files = thumbnailImageDataTransfer.files;

    form.append(thumbnailImageInputElement);
  }

  if (backgroundImage) {
    const backgroundImageDataTransfer = new DataTransfer();
    backgroundImageDataTransfer.items.add(backgroundImage);

    const backgroundImageInputElement = document.createElement("input");
    backgroundImageInputElement.type = "file";
    backgroundImageInputElement.name = "backgroundImage";
    backgroundImageInputElement.files = backgroundImageDataTransfer.files;

    form.append(backgroundImageInputElement);
  }

  const formData = new FormData(form);

  return fetchWithHengeAuth.PUT(
    {
      path: `/api/v1/vault-object?uid=${uid}`,
      ...{
        init: {
          body: formData,
        },
      },
    },
    authTokens,
  );
}

export function fetchCreateVaultObjectByInventoryAPI(
  request: CopyVaultObjectByInventoryRequest,
  authTokens: HengeAuthTokens | undefined | null,
): Promise<VaultObjectResponse> {
  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";

  const requestDataElement = document.createElement("input");
  requestDataElement.type = "text";
  requestDataElement.name = "copyVaultObjectByInventoryRequest";
  requestDataElement.value = JSON.stringify(request);

  form.append(requestDataElement);

  const formData = new FormData(form);

  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/vault-object/copy-inventory",
      ...{
        init: {
          body: formData,
        },
      },
    },
    authTokens,
  );
}

export const fetchDeleteVaultObjectPI: FetchWithHengeAuthCallback<
  VaultObjectResponse,
  {
    queryParams: {
      uid: string;
    };
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/vault-object",
      ...args,
    },
    authTokens,
  );
};

export const fetchHideVaultObjectAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { uid: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/vault-object/hide",
      ...args,
    },
    authTokens,
  );
};

export const fetchNoThumbnailVaultObjectAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { uid: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    {
      path: "/api/v1/vault-object/no-thumbnail",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetMyVaultFeedAPI: FetchWithHengeAuthCallback<
  VaultObjectListResponse,
  { json: MyVaultFeedRequest; queryParams: { q: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/vault/my/feed",
      ...args,
    },
    authTokens,
  );
};

export const fetchGetViewingVaultFeedWithHengeAuthAPI: FetchWithHengeAuthCallback<
  VaultObjectListResponse,
  { json: ViewingVaultFeedRequest; queryParams: { q: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/vault/feed",
      ...args,
    },
    authTokens,
  );
};

export const fetchLikeVaultObjectAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { uid: string } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/vault-object/like",
      ...args,
    },
    authTokens,
  );
};

export const fetchUnlikeVaultObjectAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { id: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/vault-object/like",
      ...args,
    },
    authTokens,
  );
};

export const fetchCommentVaultObjectAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { uid: string }; json: CommentVaultObjectRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    {
      path: "/api/v1/vault-object/comment",
      ...args,
    },
    authTokens,
  );
};

export const fetchDeleteCommentVaultObjectAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { id: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.DELETE(
    {
      path: "/api/v1/vault-object/comment",
      ...args,
    },
    authTokens,
  );
};

export function fetchUploadVaultObjectUploadAdditionalFileAPI(
  vaultUid: string,
  preprocessUid: string,
  file: File,
  authTokens: HengeAuthTokens,
  // abortSignal: AbortSignal,
): Promise<Response> {
  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";

  const mainFileDataTransfer = new DataTransfer();
  mainFileDataTransfer.items.add(file);

  const mainFileInputElement = document.createElement("input");
  mainFileInputElement.type = "file";
  mainFileInputElement.name = "file";
  mainFileInputElement.files = mainFileDataTransfer.files;

  form.append(mainFileInputElement);

  const formData = new FormData(form);

  return fetch(
    `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}/api/v1/vault-object/upload/additional-file?vaultUid=${vaultUid}&preprocessUid=${preprocessUid}`,
    {
      headers: {
        "x-hng-access-token": `Bearer ${authTokens.accessToken.value}`,
      },
      method: "POST",
      body: formData,
      // signal: abortSignal,
    },
  );
}

export function fetchUploadVaultObjectEditAdditionalFileAPI(
  vaultUid: string,
  vaultObjectUid: string,
  file: File,
  authTokens: HengeAuthTokens,
  // abortSignal: AbortSignal,
): Promise<Response> {
  const form = document.createElement("form");
  form.enctype = "multipart/form-data";
  form.method = "POST";

  const mainFileDataTransfer = new DataTransfer();
  mainFileDataTransfer.items.add(file);

  const mainFileInputElement = document.createElement("input");
  mainFileInputElement.type = "file";
  mainFileInputElement.name = "file";
  mainFileInputElement.files = mainFileDataTransfer.files;

  form.append(mainFileInputElement);

  const formData = new FormData(form);

  return fetch(
    `${typeof window === "undefined" ? process.env.FETCH_SSR_BASE_URL : process.env.FETCH_CSR_BASE_URL}/api/v1/vault-object/edit/additional-file?vaultUid=${vaultUid}&vaultObjectUid=${vaultObjectUid}`,
    {
      headers: {
        "x-hng-access-token": `Bearer ${authTokens.accessToken.value}`,
      },
      method: "POST",
      body: formData,
      // signal: abortSignal,
    },
  );
}

///////////////////////
//    VaultMember    //
///////////////////////

export const fetchCheckVaultMemberAPI: FetchWithHengeAuthCallback<
  CheckVaultMemberResponse,
  { queryParams: { vaultId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    { path: "/api/v1/vault-member/auth", ...args },
    authTokens,
  );
};

export const fetchJoinVaultMemberAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { vaultId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    { path: "/api/v1/vault-member/join", ...args },
    authTokens,
  );
};

export const fetchLeaveVaultMemberAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { vaultId: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    { path: "/api/v1/vault-member/leave", ...args },
    authTokens,
  );
};

export const fetchApproveVaultInvitationAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: ApproveVaultInvitationRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    { path: "/api/v1/vault-member/invite/approval", ...args },
    authTokens,
  );
};

export const fetchConfirmVaultJoinReqAPI: FetchWithHengeAuthCallback<
  HResponse,
  { json: ApproveVaultJoinReqRequest }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    { path: "/api/v1/vault-member/join/confirm", ...args },
    authTokens,
  );
};

////////////////////////
//    Notification    //
////////////////////////

export const fetchNotificationListAPI: FetchWithHengeAuthCallback<
  GetNotificationListResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    { path: "/api/v1/notification/list", ...args },
    authTokens,
  );
};

export const fetchClearNotificationAPI: FetchWithHengeAuthCallback<
  HResponse,
  { queryParams: { id: number } }
> = (args, authTokens) => {
  return fetchWithHengeAuth.PUT(
    { path: "/api/v1/notification/clear", ...args },
    authTokens,
  );
};

/////////////////////
//    Sketchfab    //
/////////////////////

export const fetchSketchfabUserAPI: FetchWithHengeAuthCallback<
  SketchfabUserResponse,
  {
    json: SketchfabRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    { path: "/api/v1/sketchfab/me", ...args },
    authTokens,
  );
};

export const fetchLoginSketchfabAPI: FetchWithHengeAuthCallback<
  SketchfabUserResponse,
  {
    json: SketchfabRequest;
  }
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    { path: "/api/v1/sketchfab/login", ...args },
    authTokens,
  );
};

export const fetchInspectSketchfabMigrationAPI: FetchWithHengeAuthCallback<
  InspectSketchfabMigrationResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    { path: "/api/v1/sketchfab/inspect", ...args },
    authTokens,
  );
};

export const fetchScanSketchfabMigrationAPI: FetchWithHengeAuthCallback<
  ScanSketchfabMigrationResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    { path: "/api/v1/sketchfab/scan", ...args },
    authTokens,
  );
};

/**
 * @return {HResponse & ApiResultEnum.ACCEPTED}
 * */
export const fetchInitializeSketchfabMigrationAPI: FetchWithHengeAuthCallback<
  HResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.POST(
    { path: "/api/v1/sketchfab/migration", ...args },
    authTokens,
  );
};

export const fetchProgressSketchfabMigrationAPI: FetchWithHengeAuthCallback<
  SketchfabMigrationSummaryResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    { path: "/api/v1/sketchfab/progress", ...args },
    authTokens,
  );
};

export const fetchSummarizeSketchfabMigrationAPI: FetchWithHengeAuthCallback<
  SketchfabMigrationSummaryResponse
> = (args, authTokens) => {
  return fetchWithHengeAuth.GET(
    { path: "/api/v1/sketchfab/summary", ...args },
    authTokens,
  );
};
