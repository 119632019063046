import { LayerDto } from "@/types/data-types";
import { CameraControls } from "@react-three/drei";
import gsap from "gsap";
import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

export type PBRTexture = {
  basecolor: undefined | string;
  normal: undefined | string;
  bump: undefined | string;
  roughness: undefined | string;
  metallic: undefined | string;
};

export interface HengeCameraControls extends CameraControls {
  autoRotate: boolean;
  distanceToHenge: number;
}

export interface HngVector2 {
  x: number;
  y: number;
}

export interface HngVector3 {
  x: number;
  y: number;
  z: number;
}

export interface HngMatrix2 {
  length: number;
  matrix: number[][];
}

export interface RowColumnCoverageMatrix {
  row: [number, number];
  column: [number, number];
}

export function changeMaterialOpacity(
  object: THREE.Object3D | undefined | null,
  opacity: number,
): void {
  if (!object) return;

  const _opacity = Math.min(1, Math.max(0, opacity));

  // object.visible = _opacity !== 0;

  object.traverse((mesh) => {
    if (!(mesh as THREE.Mesh).isMesh) return;

    (mesh as THREE.Mesh).castShadow = _opacity !== 0;
    (mesh as THREE.Mesh).receiveShadow = _opacity !== 0;

    if (!(mesh as THREE.Mesh).material) return;

    if (Array.isArray((mesh as THREE.Mesh).material)) {
      ((mesh as THREE.Mesh).material as THREE.Material[]).forEach(
        (material) => {
          if (material.isMaterial) {
            if (material.userData.gsap?.kill) {
              material.userData.gsap.kill();
            }

            material.transparent = true;
            material.opacity = _opacity;
          }
        },
      );
    } else if (((mesh as THREE.Mesh).material as THREE.Material).isMaterial) {
      if (
        ((mesh as THREE.Mesh).material as THREE.Material).userData.gsap?.kill
      ) {
        ((mesh as THREE.Mesh).material as THREE.Material).userData.gsap.kill();
      }

      ((mesh as THREE.Mesh).material as THREE.Material).transparent = true;
      ((mesh as THREE.Mesh).material as THREE.Material).opacity = _opacity;
    }
  });
}

export function makeMaterialOpaque(
  object: THREE.Object3D | undefined | null,
): void {
  return changeMaterialOpacity(object, 1);
}

export function makeMaterialTransparent(
  object: THREE.Object3D | undefined | null,
): void {
  return changeMaterialOpacity(object, 0);
}

export function changeMaterialOpacityByGsap(
  object: THREE.Object3D | undefined | null,
  opacity: number,
  delay: number,
  duration: number,
): void {
  if (!object) return;

  const _opacity = Math.min(1, Math.max(0, opacity));

  // object.visible = true;

  object.traverse((mesh) => {
    if (!(mesh as THREE.Mesh).isMesh) return;

    (mesh as THREE.Mesh).castShadow = _opacity !== 0;
    (mesh as THREE.Mesh).receiveShadow = _opacity !== 0;

    if (!(mesh as THREE.Mesh).material) return;

    if (Array.isArray((mesh as THREE.Mesh).material)) {
      ((mesh as THREE.Mesh).material as THREE.Material[]).forEach(
        (material) => {
          if (material.isMaterial) {
            if (material.userData.gsap?.kill) {
              material.userData.gsap.kill();
            }

            material.transparent = true;
            material.userData.gsap = gsap.to(material, {
              delay: delay,
              duration: duration,
              opacity: _opacity,
            });
            // .then((result) => {
            //   if (_opacity === 0) {
            //     object.visible = false;
            //   }
            // });
          }
        },
      );
    } else {
      if (((mesh as THREE.Mesh).material as THREE.Material).isMaterial) {
        if (
          ((mesh as THREE.Mesh).material as THREE.Material).userData.gsap?.kill
        ) {
          (
            (mesh as THREE.Mesh).material as THREE.Material
          ).userData.gsap.kill();
        }

        ((mesh as THREE.Mesh).material as THREE.Material).transparent = true;
        ((mesh as THREE.Mesh).material as THREE.Material).userData.gsap =
          gsap.to((mesh as THREE.Mesh).material as THREE.Material, {
            delay: delay,
            duration: duration,
            opacity: _opacity,
          });
        // .then((result) => {
        //   if (_opacity === 0) {
        //     object.visible = false;
        //   }
        // });
      }
    }
  });
}

export function makeMaterialOpaqueByGsap(
  object: THREE.Object3D | undefined | null,
  delay: number,
  duration: number,
): void {
  return changeMaterialOpacityByGsap(object, 1, delay, duration);
}

export function makeMaterialTransparentByGsap(
  object: THREE.Object3D | undefined | null,
  delay: number,
  duration: number,
): void {
  return changeMaterialOpacityByGsap(object, 0, delay, duration);
}

const PUBLIC_HENGE_CAPTURE_SIZE = [1280, 640];
const PUBLIC_HENGE_CAPTURE_OBJECT_SIZE = [512];
const PUBLIC_HENGE_CAPTURE_LOGO_SIZE = [256, 64];
const PUBLIC_HENGE_CAPTURE_PADDING = 80;

export function captureCanvasImageDataURL(
  gl: THREE.WebGLRenderer,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const originalCanvas = gl.domElement;

    // extract object image
    const extractObjectCanvas = document.createElement("canvas");
    extractObjectCanvas.width = originalCanvas.width;
    extractObjectCanvas.height = originalCanvas.height;
    const extractObjectContext = extractObjectCanvas.getContext("2d");
    if (!extractObjectContext) return;

    extractObjectContext.drawImage(
      originalCanvas,
      0,
      0,
      originalCanvas.width,
      originalCanvas.height,
    );

    const extractObjectImageData = extractObjectContext.getImageData(
      0,
      0,
      extractObjectCanvas.width,
      extractObjectCanvas.height,
    );

    let objectImageMinX = extractObjectCanvas.width;
    let objectImageMaxX = 0;
    let objectImageMinY = extractObjectCanvas.height;
    let objectImageMaxY = 0;

    // find min, max pixel of object image
    for (let y = 0; y < extractObjectImageData.height; y++) {
      for (let x = 0; x < extractObjectImageData.width; x++) {
        const index = (y * extractObjectImageData.width + x) * 4;
        const red = extractObjectImageData.data[index];
        const green = extractObjectImageData.data[index + 1];
        const blue = extractObjectImageData.data[index + 2];
        const alpha = extractObjectImageData.data[index + 3];

        // skip alpha background
        if (red === 0 && green === 0 && blue === 0 && alpha === 0) {
          continue;
        }

        // update min, max
        if (x < objectImageMinX) objectImageMinX = x;
        if (x > objectImageMaxX) objectImageMaxX = x;
        if (y < objectImageMinY) objectImageMinY = y;
        if (y > objectImageMaxY) objectImageMaxY = y;
      }
    }

    // make width as multiple of 2
    if ((objectImageMaxX - objectImageMinX) % 2 === 1) {
      objectImageMaxX += 1;
    }
    if ((objectImageMinY - objectImageMinY) % 2 === 1) {
      objectImageMaxY += 1;
    }
    const objectImageWidth = objectImageMaxX - objectImageMinX;
    const objectImageHeight = objectImageMaxY - objectImageMinY;

    // assert
    if (
      objectImageMinX === 0 ||
      objectImageMinY === 0 ||
      objectImageMinX > objectImageMaxX ||
      objectImageMinY > objectImageMaxY
    ) {
      return;
    }

    const resizeWidth = PUBLIC_HENGE_CAPTURE_OBJECT_SIZE[0];
    const resizeHeight =
      (objectImageHeight / objectImageWidth) *
      PUBLIC_HENGE_CAPTURE_OBJECT_SIZE[0];

    // draw final canvas
    const finalCanvas = document.createElement("canvas");
    finalCanvas.width = PUBLIC_HENGE_CAPTURE_SIZE[0];
    finalCanvas.height = PUBLIC_HENGE_CAPTURE_SIZE[1];
    const finalContext = finalCanvas.getContext("2d");
    if (!finalContext) return;

    // fill background color
    finalContext.fillStyle = "#ffffff";
    finalContext.fillRect(0, 0, finalCanvas.width, finalCanvas.height);

    // draw object
    finalContext.drawImage(
      extractObjectCanvas,
      objectImageMinX,
      objectImageMinY,
      objectImageWidth,
      objectImageHeight,
      PUBLIC_HENGE_CAPTURE_SIZE[0] / 2 - resizeWidth / 2,
      PUBLIC_HENGE_CAPTURE_SIZE[1] -
        resizeHeight -
        PUBLIC_HENGE_CAPTURE_PADDING,
      resizeWidth,
      resizeHeight,
    );

    // draw logo
    const logo = new Image();
    logo.src = "/assets/logos/symbol-and-text.png";

    logo.onload = function () {
      finalContext.drawImage(
        logo,
        PUBLIC_HENGE_CAPTURE_SIZE[0] / 2 -
          PUBLIC_HENGE_CAPTURE_LOGO_SIZE[0] / 2,
        PUBLIC_HENGE_CAPTURE_PADDING,
        PUBLIC_HENGE_CAPTURE_LOGO_SIZE[0],
        PUBLIC_HENGE_CAPTURE_LOGO_SIZE[1],
      );

      const dataURL = finalCanvas.toDataURL("image/jpg");

      resolve(dataURL);
    };

    logo.onerror = function (error) {
      reject(error);
    };
  });
}

export const MeshStandardMaterialParameterKeys = [
  "color",
  "roughness",
  "metalness",
  "map",
  "lightMap",
  "lightMapIntensity",
  "aoMap",
  "aoMapIntensity",
  "emissive",
  "emissiveIntensity",
  "emissiveMap",
  "bumpMap",
  "bumpScale",
  "normalMap",
  "normalMapType",
  "normalScale",
  "displacementMap",
  "displacementScale",
  "displacementBias",
  "roughnessMap",
  "metalnessMap",
  "alphaMap",
  "envMap",
  "envMapRotation",
  "envMapIntensity",
  "wireframe",
  "wireframeLinewidth",
  "fog",
  "flatShading",
  // "alphaHash",
  // "alphaTest",
  // "alphaToCoverage",
  // "blendAlpha",
  // "blendColor",
  // "blendDst",
  // "blendDstAlpha",
  // "blendEquation",
  // "blendEquationAlpha",
  // "blending",
  // "blendSrc",
  // "blendSrcAlpha",
  // "clipIntersection",
  // "clipShadow",
  // "clippingPlanes",
  // "colorWrite",
  // "defines",
  // "depthFunc",
  // "depthTest",
  // "depthWrite",
  // "dithering",
  // "forceSinglePass",
  // "name",
  // "polygonOffset",
  // "polygonOffsetFactor",
  // "polygonOffsetUnits",
  // "precision",
  "side",
  // "stencilFail",
  // "stencilFunc",
  // "stencilFuncMask",
  // "stencilRef",
  // "stencilWrite",
  // "stencilWriteMask",
  // "stencilZFail",
  // "stencilZPass",
  // "transparent",
  // "toneMapped",
  // "userData",
  // "vertexColors"
  // "wireframeLinecap",
  // "wireframeLinejoin",
];

export function getVertexCount(object: THREE.Object3D) {
  let count = 0;

  object.traverse((o) => {
    if ("geometry" in o) {
      const geometry: THREE.BufferGeometry = o.geometry as THREE.BufferGeometry;

      // BufferGeometry 확인
      const positionAttribute = geometry.attributes.position;
      if (positionAttribute) {
        count += positionAttribute.count; // 정점의 개수
      }
    }
  });

  return count;
}

export function getPointerFromCoordinate(
  x: number,
  width: number,
  y: number,
  height: number,
): THREE.Vector2 {
  return new THREE.Vector2((x / width) * 2 - 1, (y / height) * -2 + 1);
}

export function getCoordinateFromPointer(
  position: THREE.Vector3,
  camera: THREE.Camera,
  canvas: HTMLCanvasElement,
) {
  // position은 THREE.Vector3
  const vector = position.clone();

  // 3D 좌표를 표준화된 장치 좌표(NDC)로 변환
  vector.project(camera);

  // NDC 좌표를 화면 좌표로 변환
  const x = (vector.x * 0.5 + 0.5) * canvas.clientWidth;
  const y = (-vector.y * 0.5 + 0.5) * canvas.clientHeight;

  return new THREE.Vector2(x, y);
}

export function injectLayersData(root: THREE.Object3D) {
  const depthIndexMap = new Map(); // 각 depth별 현재 index를 추적

  function traverse(node: THREE.Object3D, depth = 0, path = "") {
    // 현재 depth의 index 계산
    if (!depthIndexMap.has(depth)) {
      depthIndexMap.set(depth, 0);
    }
    const currentIndex = depthIndexMap.get(depth);
    depthIndexMap.set(depth, currentIndex + 1);

    // 현재 노드에 depth와 index 정보 추가
    const nodeWithDepth: LayerDto = {
      type: node.type,
      depth,
      idx: currentIndex,
      path: !path ? "0" : `${path}_${currentIndex}`,
      name: node.name,
      originalName: node.userData.name ?? node.name,
    };
    node.userData.layer = nodeWithDepth;

    // 자식 노드들을 재귀적으로 순회
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        traverse(child, depth + 1, nodeWithDepth.path);
      });
    }
  }

  traverse(root);
}

export function traverseLayers(root: THREE.Object3D) {
  const result: LayerDto[] = [];
  const depthIndexMap = new Map(); // 각 depth별 현재 index를 추적

  function traverse(node: THREE.Object3D, depth = 0, path = "") {
    // 현재 depth의 index 계산
    if (!depthIndexMap.has(depth)) {
      depthIndexMap.set(depth, 0);
    }
    const currentIndex = depthIndexMap.get(depth);
    depthIndexMap.set(depth, currentIndex + 1);

    // 현재 노드에 depth와 index 정보 추가
    const nodeWithDepth: LayerDto = {
      type: node.type,
      depth,
      idx: currentIndex,
      path: !path ? "0" : `${path}_${currentIndex}`,
      name: node.name,
      originalName: node.userData.name ?? node.name,
    };
    node.userData.layer = nodeWithDepth;

    if ((node as THREE.Mesh).isMesh) {
      const material = (node as THREE.Mesh).material;
      if (
        material &&
        (material as THREE.MeshPhysicalMaterial).isMeshPhysicalMaterial
      ) {
        nodeWithDepth.material = {
          side: (material as THREE.MeshPhysicalMaterial).side,
          color: `#${(material as THREE.MeshPhysicalMaterial).color.getHexString()}`,
          opacity: (material as THREE.MeshPhysicalMaterial).opacity,
          roughness: (material as THREE.MeshPhysicalMaterial).roughness,
          metalness: (material as THREE.MeshPhysicalMaterial).metalness,
          emissive: `#${(
            material as THREE.MeshPhysicalMaterial
          ).emissive.getHexString()}`,
        };

        const baseColorMap = (material as THREE.MeshPhysicalMaterial).map;
        if (baseColorMap && baseColorMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            baseColorMap: {
              name: `${nodeWithDepth.path}-baseColorMap`,
              url: baseColorMap.image.src,
              thumbnailUrl: baseColorMap.image.src,
            },
          };
        }
        const metalnessMap = (material as THREE.MeshPhysicalMaterial)
          .metalnessMap;
        if (metalnessMap && metalnessMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            metalnessMap: {
              name: `${nodeWithDepth.path}-metalnessMap`,
              url: metalnessMap.image.src,
              thumbnailUrl: metalnessMap.image.src,
            },
          };
        }
        const roughnessMap = (material as THREE.MeshPhysicalMaterial)
          .roughnessMap;
        if (roughnessMap && roughnessMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            roughnessMap: {
              name: `${nodeWithDepth.path}-roughnessMap`,
              url: roughnessMap.image.src,
              thumbnailUrl: roughnessMap.image.src,
            },
          };
        }
        const normalMap = (material as THREE.MeshPhysicalMaterial).normalMap;
        if (normalMap && normalMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            normalMap: {
              name: `${nodeWithDepth.path}-normalMap`,
              url: normalMap.image.src,
              thumbnailUrl: normalMap.image.src,
            },
          };
        }
        const bumpMap = (material as THREE.MeshPhysicalMaterial).bumpMap;
        if (bumpMap && bumpMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            bumpMap: {
              name: `${nodeWithDepth.path}-bumpMap`,
              url: bumpMap.image.src,
              thumbnailUrl: bumpMap.image.src,
            },
          };
        }
        const displacementMap = (material as THREE.MeshPhysicalMaterial)
          .displacementMap;
        if (displacementMap && displacementMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            displacementMap: {
              name: `${nodeWithDepth.path}-displacementMap`,
              url: displacementMap.image.src,
              thumbnailUrl: displacementMap.image.src,
            },
          };
        }
        const aoMap = (material as THREE.MeshPhysicalMaterial).aoMap;
        if (aoMap && aoMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            aoMap: {
              name: `${nodeWithDepth.path}-aoMap`,
              url: aoMap.image.src,
              thumbnailUrl: aoMap.image.src,
            },
          };
        }
        const emissiveMap = (material as THREE.MeshPhysicalMaterial)
          .emissiveMap;
        if (emissiveMap && emissiveMap.image.src) {
          nodeWithDepth.texture = {
            ...nodeWithDepth.texture,
            emissiveMap: {
              name: `${nodeWithDepth.path}-emissiveMap`,
              url: emissiveMap.image.src,
              thumbnailUrl: emissiveMap.image.src,
            },
          };
        }
      }
    }
    result.push(nodeWithDepth);

    // 자식 노드들을 재귀적으로 순회
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        traverse(child, depth + 1, nodeWithDepth.path);
      });
    }
  }

  traverse(root);
  return result;
}

export function getObjectByLayerData(root: THREE.Object3D, layer: LayerDto) {
  let result: THREE.Object3D | null = null;

  root.traverse((o) => {
    if (result) return;
    if (o.userData?.layer?.path === layer.path) {
      result = o;
    }
  });

  return result;
}

export function generateRandomHexColor(): string {
  // 0부터 0xFFFFFF(16777215) 사이의 랜덤한 숫자 생성
  const randomNumber = Math.floor(Math.random() * 0xffffff);

  // 16진수로 변환하고 앞에 #을 붙임
  // padStart(6, '0')으로 6자리가 되도록 앞에 0을 채움
  return "#" + randomNumber.toString(16).padStart(6, "0");
}

export const createBoxEdges = (
  box: THREE.Box3,
  color: THREE.ColorRepresentation,
) => {
  const { min, max } = box;
  const edges = new THREE.Group();

  // 모서리 선분들을 정의
  const edgeSegments = [
    // 아래 사각형
    [
      [min.x, min.y, min.z],
      [max.x, min.y, min.z],
    ],
    [
      [max.x, min.y, min.z],
      [max.x, min.y, max.z],
    ],
    [
      [max.x, min.y, max.z],
      [min.x, min.y, max.z],
    ],
    [
      [min.x, min.y, max.z],
      [min.x, min.y, min.z],
    ],

    // 위 사각형
    [
      [min.x, max.y, min.z],
      [max.x, max.y, min.z],
    ],
    [
      [max.x, max.y, min.z],
      [max.x, max.y, max.z],
    ],
    [
      [max.x, max.y, max.z],
      [min.x, max.y, max.z],
    ],
    [
      [min.x, max.y, max.z],
      [min.x, max.y, min.z],
    ],

    // 수직 연결선
    [
      [min.x, min.y, min.z],
      [min.x, max.y, min.z],
    ],
    [
      [max.x, min.y, min.z],
      [max.x, max.y, min.z],
    ],
    [
      [max.x, min.y, max.z],
      [max.x, max.y, max.z],
    ],
    [
      [min.x, min.y, max.z],
      [min.x, max.y, max.z],
    ],
  ];

  // 각 선분을 개별적인 Line2 객체로 생성
  edgeSegments.forEach(([[x1, y1, z1], [x2, y2, z2]]) => {
    const geometry = new LineGeometry();
    geometry.setPositions([x1, y1, z1, x2, y2, z2]);

    const material = new LineMaterial({
      color: color,
      linewidth: 4,
      resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
    });

    const line = new Line2(geometry, material);
    edges.add(line);
  });

  return edges;
};
